import React, { useState } from 'react'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import Heading from '../../components/Heading'

import {
  SectionOne,
  SectionTwo,
  SectionFeatureAccount,
  SectionAboutAccount,
  SectionBetweenAccounts,
  SectionSix
} from '../../partials/AccountPage'

import {
  SectionCodeBank,
  SectionStepsRegister,
  SectionStores,
  SideSection
} from '../../partials/commons'

import getStaticImage from '../../hooks/getStaticImage'

const AccountPage = (): JSX.Element => {
  const [toggleForm, setToggleForm] = useState(false)

  const toggle = (): void => {
    setToggleForm(true)
  }

  const schemaImg = getStaticImage('cora-conta-digital.png')
  const schema = {
    title: 'Conta Digital',
    images: [schemaImg],
    type: 'Product',
    description:
      'Conta digital para empresas que oferece cartão empresarial, emissão de boletos, projeção de fluxo de caixa, pagamento de contas e impostos, suporte no aplicativo e acesso múltiplo para sócios',
  }

  return (
    <Layout pageType="conta-pj-digital" toggle={toggle}>
      <SEO
        title="Conta PJ completa, gratuita, digital e sem burocracias - Cora"
        description="Abra sua conta PJ gratuita na Cora, sem burocracias e livre de taxas. A conta digital PJ de quem empreende. Contrate sua conta gratuita agora"
        schema={schema}
        linkCanonical="conta-pj-digital"
      />
      <SectionOne toggle={toggle} />
      <SectionTwo />
      <SectionFeatureAccount />
      <SectionAboutAccount />
      <SectionBetweenAccounts />
      <SectionCodeBank>
        <Heading headingLevel="h4" size="xlarge">
          Você pode confiar.
        </Heading>
        <span>
          A Cora é autorizada e regulada pelo Banco Central. Nosso código
          bancário é o <strong>403</strong>.<br />
          <br />
          Operamos com a licença de Sociedade de Crédito Direto e o seu saldo
          disponível na Cora é depositado diariamente em contas do{' '}
          <strong>Banco Central</strong>.
        </span>
      </SectionCodeBank>
      <SectionStepsRegister />
      <SectionSix />
      <SectionStores
        title="Cadastre sua empresa"
        text="Aproveite a conta PJ gratuita e fácil de abrir."
        linkText="Tão simples quanto baixar um app"
        pageType="conta-pj-digital"
      />
      <SideSection
        toggleForm={toggleForm}
        setToggleForm={setToggleForm}
        idFinishForm="FormFinishedContaDigital"
        pageType="conta-pj-digital"
      />
    </Layout>
  )
}

export default AccountPage
