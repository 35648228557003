import React, { useEffect, useRef, useState } from 'react'

import Heading from '../../../components/Heading'
import Button from '../../../components/Button'

import * as Styled from './styled'
import getStaticImage from '../../../hooks/getStaticImage'

const SectionOne = ({ toggle }: SectionOneProps): JSX.Element => {

  const buttonRef = useRef() as any
  const [sticky, setSticky] = useState<boolean>(false)

  const onScroll = () => {
    const offsetTopElement = buttonRef?.current?.offsetTop

    if (window.pageYOffset >= offsetTopElement) {
      setSticky(true)
    } else if (window.pageYOffset < offsetTopElement) {
      setSticky(false)
    }
  }

  useEffect(() => {
    if (window.innerWidth > 520) { return }

    window.addEventListener("scroll", onScroll)

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [])

  return (
    <Styled.Section>
      <div className="container container--no-mobile-margin">
        <Styled.LeftSide>
          <Heading headingLevel="h1" size="xlarge">É uma Conta PJ. Mas não como você conhece.</Heading>
          <Styled.Text className="s-text-seo">
            Conta PJ digital gratuita, livre de burocracias e com cadastro em minutos. Ideal para quem empreende.
          </Styled.Text>
          <Styled.ButtonWrapper className={sticky ? 'sticky' : ''}>
            <Button handleClick={toggle} size="large" mobileShadow={sticky ? true : false}>Abrir conta PJ gratuita</Button>
          </Styled.ButtonWrapper>
        </Styled.LeftSide>
        <Styled.RightSide>
          <div className='gatsby-image-wrapper'>
            <img src={getStaticImage('hero-conta-pj-digital-mobile.png')} style={{ width: "100%" }} />
          </div>
        </Styled.RightSide>
      </div>
    </Styled.Section>
  )
}

export default SectionOne
